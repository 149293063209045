import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import {
  labCommunityPath,
  labCommunityPostPath,
  labCoursePath,
  labCoursesPath,
  labEventPath,
  labEventsPath
} from '../../../utils/routeHelpers'
import classNames from 'classnames'
import styles from './../../../components/_advancedSearch.module.scss'
import { t } from '../../../utils'
import { useLazyQuery } from '@apollo/client'
import {
  CommunityPost,
  LabCourse,
  LabEvent,
  LabSearch
} from '../../../__generated__/graphql'
import { LAB_SEARCH_QUERY } from '../../../gql'
import Image from 'next/legacy/image'
import dayjs from 'dayjs'
import LabImageComponent from './LabImageComponent'
interface LabSearchProps {
  filters?: (keyof SearchResultType)[]
  customClassName?: string
  placeholder?: string
}

interface SearchResultType {
  courses?: Pick<LabCourse, 'id' | 'title' | 'cover'>[]
  events?: Pick<LabEvent, 'id' | 'title' | 'image' | 'date'>[]
  posts?: Pick<
    CommunityPost,
    'id' | 'title' | 'category' | 'author' | 'createdAt'
  >[]
}

const initialSearchResult: SearchResultType = {
  courses: [],
  events: [],
  posts: []
}

const isAny = (
  searchResult: SearchResultType,
  filters: (keyof SearchResultType)[]
): boolean => {
  return filters.reduce((sum, f) => sum + searchResult[f]?.length, 0) > 0
}

const LabHeaderSearch: React.FC<LabSearchProps> = ({
  filters = ['events', 'courses', 'posts'],
  customClassName,
  placeholder
}) => {
  const [searchResult, setSearchResult] =
    useState<SearchResultType>(initialSearchResult)
  const [query, setQuery] = useState('')

  const [performSearch] = useLazyQuery(LAB_SEARCH_QUERY, {
    onCompleted: (data) => {
      setSearchResult(data.labSearch as LabSearch)
    }
  })

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const queryString = event.target.value
    setQuery(queryString)

    performSearch({
      variables: {
        q: queryString
      }
    })
  }

  return (
    <form
      className={classNames(
        styles.advancedSearch,
        customClassName,
        'control has-icons-left'
      )}
      onSubmit={(e) => e.preventDefault()} // to not handle "enter" pressed
    >
      <input
        name="search"
        className="input"
        type="text"
        onChange={handleSearch}
        placeholder={placeholder}
      />
      <span className="icon is-small is-left">
        <FontAwesomeIcon icon={faSearch} />
      </span>
      <input type="submit" className="is-hidden" />

      {isAny(searchResult, filters) && query.length > 0 && (
        <div className={styles.searchDropdown}>
          {filters.includes('courses') && searchResult.courses.length > 0 && (
            <section className={styles.courses}>
              <header>
                <h2>{t('Courses')}</h2>
                <a className={styles.viewAll} href={labCoursesPath()}>
                  {t('View All')}
                </a>
              </header>
              <ul className="columns is-flex-wrap">
                {searchResult.courses.map((course) => (
                  <li className="column is-one-third" key={course.id}>
                    <a
                      className={styles.searchThing}
                      href={labCoursePath(course.id)}
                    >
                      <LabImageComponent
                        src={course.cover.medium}
                        alt={course.title}
                        width={128}
                        height={56}
                      />
                      <h3>
                        <strong>{course.title}</strong>
                      </h3>
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {filters.includes('events') && searchResult.events.length > 0 && (
            <section className={styles.events}>
              <header>
                <h2>{t('Events')}</h2>
                <a className={styles.viewAll} href={labEventsPath()}>
                  {t('View All')}
                </a>
              </header>
              <ul className="columns is-flex-wrap">
                {searchResult.events.map((event) => (
                  <li className="column is-one-third" key={event.id}>
                    <a
                      className={styles.searchThing}
                      href={labEventPath(event.id)}
                    >
                      <LabImageComponent
                        src={event.image.medium}
                        alt={event.title}
                        width={128}
                        height={68}
                      />
                      <h3>
                        <strong>{event.title}</strong>
                        {dayjs(event.date).format('MMM DD, YYYY')}
                      </h3>
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {filters.includes('posts') && searchResult.posts.length > 0 && (
            <section className={styles.posts}>
              <header>
                <h2>{t('Posts')}</h2>
                <a className={styles.viewAll} href={labCommunityPath()}>
                  {t('View All')}
                </a>
              </header>
              <ul className="columns is-flex-wrap">
                {searchResult.posts.map((post: CommunityPost) => {
                  const authorFullName =
                    post.author.firstName + ' ' + post.author.lastName
                  return (
                    <li className="column" key={post.id}>
                      <a
                        className={styles.searchThing}
                        href={labCommunityPostPath(post)}
                      >
                        <div className={styles.imgContainer}>
                          <Image
                            src={post.author.picture}
                            alt={authorFullName}
                            width={46}
                            height={46}
                            layout="fixed"
                          />
                        </div>
                        <h3>
                          {authorFullName}
                          <em className="is-block">
                            {dayjs(post.createdAt).format('MMM DD, YYYY')}
                          </em>
                          <strong>{post.title}</strong>
                        </h3>
                      </a>
                    </li>
                  )
                })}
              </ul>
            </section>
          )}
        </div>
      )}
    </form>
  )
}

export default LabHeaderSearch

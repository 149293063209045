import { useContext, useState } from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsis,
  faSearch,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import Image from 'next/legacy/image'
import styles from './../../../components/_header.module.scss'
import classnames from 'classnames'
import { BlockScrollContext } from '../../_app'
import { ModalsContext } from '../../../utils/modals'
import { useAuth } from '../../../utils/auth'
import { t } from '../../../utils'
import {
  labChatPath,
  labCommunityPath,
  labDealsPath,
  labPath,
  labTrainingPath
} from '../../../utils/routeHelpers'
import labLogoImg from '@public/images/lab_logo.png'
import { useRouter } from 'next/router'
import LabHeaderSearch from './LabHeaderSearch'
import Avatar from '../../../components/Avatar'
import Announcements from '../../../components/Announcements'

const LabSearch = () => (
  <LabHeaderSearch
    customClassName={styles.labSearchForm}
    placeholder={t('courses, events, posts')}
  />
)

const LabHeader: React.FC = () => {
  const { currentUser } = useAuth()
  const router = useRouter()

  const [isMenuOpen, toggleMenu] = useState(false)
  const { setBlockScroll } = useContext(BlockScrollContext)
  const { openModal, closeModal } = useContext(ModalsContext)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const openSignInModal = () => {
    openModal({
      name: 'SignIn',
      props: {
        onClose: closeModal
      },
      modalTitle: t('Sign In')
    })
  }

  return (
    <div className={classnames('header-container', styles.labHeaderContainer)}>
      <header
        role="banner"
        className={classnames(styles.header, styles.labHeader)}
      >
        <button
          className={classnames(styles.hamburger, styles.hamburgerRight)}
          type="button"
          onClick={() => {
            toggleMenu(!isMenuOpen)
            setBlockScroll(!isMenuOpen)
          }}
        >
          {isMenuOpen ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faEllipsis} />
          )}
        </button>

        <Link href={labPath()} prefetch={false} className={styles.logo}>
          <Image
            src={labLogoImg}
            alt="Worship Online Lab"
            width={156}
            height={83}
          />
        </Link>

        <>
          <div
            className={classnames(
              isMenuOpen ? styles.menuOpen : styles.menuClosed,
              styles.labHeaderMenuContainer
            )}
          >
            {isMenuOpen && (
              <div className={styles.mobileSearch}>
                {!isSearchOpen && (
                  <div
                    className={classnames(styles.labSearchIcon)}
                    onClick={() => {
                      setIsSearchOpen(true)
                    }}
                  >
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                  </div>
                )}
                <div
                  className={classnames(styles.searchContainer, {
                    [styles.searchContainerOpen]: isSearchOpen
                  })}
                >
                  <LabSearch />
                  <div
                    className={styles.closeSearch}
                    onClick={() => {
                      setIsSearchOpen(false)
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              </div>
            )}
            <nav className={styles.nav} role="nav" aria-label="main navigation">
              <ul>
                <li>
                  <Link
                    href={labPath()}
                    prefetch={false}
                    onClick={() => setBlockScroll(!isMenuOpen)}
                    className={classnames({
                      [styles.active]: router.route.endsWith(labPath())
                    })}
                  >
                    {t('Feed')}
                  </Link>
                </li>
                <li>
                  <Link
                    href={labTrainingPath()}
                    prefetch={false}
                    onClick={() => setBlockScroll(!isMenuOpen)}
                    className={classnames({
                      [styles.active]: router.route.includes(labTrainingPath())
                    })}
                  >
                    {t('Training')}
                  </Link>
                </li>
                <li>
                  <Link
                    href={labCommunityPath()}
                    prefetch={false}
                    onClick={() => setBlockScroll(!isMenuOpen)}
                    className={classnames({
                      [styles.active]: router.route.includes(labCommunityPath())
                    })}
                  >
                    {t('Community')}
                  </Link>
                </li>

                <li>
                  <Link
                    href={labDealsPath()}
                    prefetch={false}
                    onClick={() => setBlockScroll(!isMenuOpen)}
                    className={classnames({
                      [styles.active]: router.route.includes(labDealsPath())
                    })}
                  >
                    {t('Deals')}
                  </Link>
                </li>

                <li>
                  <Link
                    href={labChatPath()}
                    prefetch={false}
                    className={styles.chat}
                    onClick={() => setBlockScroll(!isMenuOpen)}
                  >
                    {t('Chat AI')}
                  </Link>
                </li>
              </ul>
            </nav>

            <LabSearch />

            {currentUser ? (
              <div className={styles.avatarContainer}>
                <div className="is-hidden-touch">
                  <Announcements />
                </div>
                <Avatar />
              </div>
            ) : (
              <div className={styles.signoutOptions}>
                <button className="button is-regular" onClick={openSignInModal}>
                  {t('Sign In')}
                </button>
              </div>
            )}
          </div>
        </>
        {currentUser && (
          <div className="is-hidden-desktop">
            <Announcements />
          </div>
        )}
      </header>
    </div>
  )
}

export default LabHeader

import { gql } from '../../__generated__/gql'
export const LAB_SEARCH_QUERY = gql(`
  query labSearch($q: String!) {
    labSearch(q: $q) {
      courses {
        id
        title
        cover {
          medium
        }
      }
      events {
        id
        title
        image {
          medium
        }
        date
      }
      posts {
        id
        title
        category {
          title
        }
        createdAt
        author {
          firstName
          lastName
          picture
        }
      }
    }
  }
`)
